import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

export default function Header({ children }) {
  const headerMetadata = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            headerMetadata {
              lang
              title
              description
              canonicalUrl
              themeColor
            }
          }
        }
      }
    `
  ).site.siteMetadata.headerMetadata

  return (
    <>
      <Helmet title={headerMetadata.title}>
        <link rel="canonical" href={headerMetadata.canonicalUrl} />
        <html lang={headerMetadata.lang} />
        <meta name="theme-color" content={headerMetadata.themeColor} />
        <meta name="description" content={headerMetadata.description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Helmet>
      {children}
    </>
  )
}
